import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
function Services()
{
    useEffect(() => {
        document.title = 'Services - Blackpearllogics';
      }, []);

    return(
        <div>

       
        <div className="container-fuild abt-sec1">
            <div className="container">
               <div className="row">
                <div className="col-sm-12">

               
                <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/"><img src={require('../images/icons/home.png')} /> Home</Link></li>
    <li class="breadcrumb-item active" aria-current="page">About us</li>
  </ol>
</nav>
</div>
               </div>
                
            </div>

        </div>

        <div className="container-fuild home-sec-2">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-12 heading text-center">
                        <h2>
                        Elevate Your Online Presence<br />
                        with Expert Web Solutions!
                        </h2>
                        <p>
                        Our expert team combines cutting-edge technology with artistic flair to create stunning,<br/>user-friendly websites that captivate audiences and convert visitors into loyal customers.
                        </p>
                    </div>
                </div>
                <div className="row home-sec-2-row">
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-1"></div>
                            <div className="col-9">
                                <h3>All in one package</h3>
                               <p>Blackpearllogics offers "all-in-one" package for a website typically includes a comprehensive range of services to cover all aspects of creating and launching a website.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-2"></div>
                            <div className="col-9">
                                <h3>Consultation and Planning</h3>
                                <p> Initial consultation to understand your goals, target audience, and preferences.
        Development of a project plan and timeline.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-3"></div>
                            <div className="col-9">
                                <h3>Domain Name and Hosting</h3>
                                <p> Assistance with selecting and registering a domain name (your website's address).
        Set up and configuration of web hosting services.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-4"></div>
                            <div className="col-9">
                                <h3>Design and Development</h3>
                                <p>Customized website design based on your brand and preferences.
        Creation of a responsive layout (compatible with all devices).
        Development of essential pages (home, about, services, contact, etc.).
        Integration of interactive elements (forms, sliders, galleries, etc.).</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-5"></div>
                            <div className="col-9">
                                <h3>Content Creation and Integration</h3>
                               <p> Assistance with content creation or migration (text, images, videos, etc.).
        Integration of content into the website in a visually appealing manner.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-6"></div>
                            <div className="col-9">
                                <h3>SEO and Optimization</h3>
                                <p>On-page SEO optimization to improve search engine rankings.
        Optimization for speed and performance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-7"></div>
                            <div className="col-9">
                                <h3>Security and Maintenance</h3>
                                <p>Implementation of security measures to protect against threats and vulnerabilities.
        Regular updates, backups, and maintenance to keep the site running smoothly.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-8"></div>
                            <div className="col-9">
                                <h3>Testing and Quality Assurance</h3>
                                <p>Thorough testing to ensure the website functions properly across different browsers and devices.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-9"></div>
                            <div className="col-9">
                                <h3>Training and Support</h3>
                                <ul>
                                    <li>Training on how to update and manage the website (if needed).</li>
                                    <li>Ongoing support for any technical issues or updates.</li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-10"></div>
                            <div className="col-9">
                                <h3>Analytics and Reporting</h3>
                                <ul>
                                    <li>Integration of analytics tools to track website traffic and user behavior.</li>
                                    <li>Regular reporting on website performance.</li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>  <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-11"></div>
                            <div className="col-9">
                                <h3>  Additional Features (Optional):</h3>
                                <ul>
                                    <li>E-commerce functionality for online stores.</li>
                                    <li>Blog integration for content marketing.</li>
                                    <li>Social media integration.</li>
                                    <li>Newsletter sign-up forms.</li>
                                </ul>
                               
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <a href="https://wa.me/+919159565854?text=Hello BPL!" className="whatsapp-button" target="_blank">
    
    <img src={require('../images/WhatsApp.svg').default} alt='whatsapp' />
    </a>

        </div>

    );
}
export default Services;