import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import img01 from '../images/portfolio/01.png';
import img02 from '../images/portfolio/02.png';
import img03 from '../images/portfolio/03.png';
import img04 from '../images/portfolio/04.png';
import img05 from '../images/portfolio/05.png';
import img06 from '../images/portfolio/06.png';
import img07 from '../images/portfolio/07.png';
import img08 from '../images/portfolio/08.png';
import img09 from '../images/portfolio/09.png';
import img10 from '../images/portfolio/10.png';
import img11 from '../images/portfolio/11.png';
import img12 from '../images/portfolio/12.png';
import img13 from '../images/portfolio/13.png';
import img14 from '../images/portfolio/14.png';
import img15 from '../images/portfolio/15.png';
import img16 from '../images/portfolio/16.png';
import img17 from '../images/portfolio/17.png';
import img18 from '../images/portfolio/18.png';
import img19 from '../images/portfolio/19.png';

function Portfolio()
{
	useEffect(() => {
        document.title = 'Portfolio -  Blackpearllogics';
      }, []);

    return(
        <div>
   
<div className="container-fuild abt-sec1">
            <div className="container">
               <div className="row">
                <div className="col-sm-12">

               
                <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/"><img src={require('../images/icons/home.png')} /> Home</Link></li>
    <li class="breadcrumb-item active" aria-current="page">Portfolio</li>
  </ol>
</nav>
</div>
               </div>
                
            </div>

        </div>

        <div className="container-fuild home-sec-2 Products-sec-1">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-12 heading text-center">
                        <h2>
						Portfolio
                        </h2>
                        <p>
                        Blackpearllogics not just about websites; we're about turning your eCommerce vision into a thriving reality.<br/>Our team of expert developers, designers, and strategists collaborate to bring your unique brand to life in the digital world.
                        </p>
                    </div>
                </div>
               
             

            
      
  <div className="gallery">
    <div className="view overlay hm-black-light gallery-item">
	<a href="https://www.zoneadds.com" target={"_blank"}>
      <img src={img01} />
      <div className="mask flex-center">
        <p className="white-text">Zoneadds</p>
		
      </div>
	  </a>
    </div>

		
	<div className="view overlay hm-black-light gallery-item">
	<a href="https://ceyloncurry.de/" target={"_blank"}>
	<img src={img12} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Ceylon Curry</p>
      </div>
	  </a>
    </div>

    <div className="view overlay hm-black-light gallery-item">

	<a href="https://outlookhrs.com/" target={"_blank"}>
      <img src={img02} />
      <div className="mask flex-center">
        <p className="white-text">Outlookhrs</p>
      </div>
	  </a>
    </div>

	<div className="view overlay hm-black-light gallery-item">
	<a href="http://snacky.co.in/" target={"_blank"}>
	<img src={img07} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Snacky</p>
      </div>
	  </a>
    </div>




	<div className="view overlay hm-black-light gallery-item">
	<a href="https://iapmrcon2024.in/" target={"_blank"}>
	<img src={img13} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Iapmrcon 2024</p>
      </div>
	  </a>
    </div>
	
    <div className="view overlay hm-black-light gallery-item">
	<a href="https://www.fabrofashion.in/" target={"_blank"}>
     <img src={img05} />
      <div className="mask flex-center">
        <p className="white-text">Fabro Fashion</p>
      </div>
	  </a>
    </div>
    <div className="view overlay hm-black-light gallery-item">
	<a href="https://hausobrands.com/" target={"_blank"}>
	<img src={img06} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Hauso Brands</p>
      </div>
	  </a>
    </div>

    <div className="view overlay hm-black-light gallery-item">
	<a href="https://dnaexports.in/" target={"_blank"}>
     <img src={img03} />
      <div className="mask flex-center">
        <p className="white-text">DNA Exports</p>
      </div>
	  </a>
    </div>
	

	<div className="view overlay hm-black-light gallery-item">
	<a href="http://styleway.co.in/" target={"_blank"}>
	<img src={img08} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Styleway</p>
      </div>
	  </a>
    </div>
	<div className="view overlay hm-black-light gallery-item">
	<a href="http://tourz2explore.com/" target={"_blank"}>
	<img src={img09} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Tourz 2 Explore</p>
      </div>
	  </a>
    </div>
	<div className="view overlay hm-black-light gallery-item">
	<a href="http://vediczone.in/" target={"_blank"}>
	<img src={img10} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Vedic Zone</p>
      </div>
	  </a>
    </div>

	<div className="view overlay hm-black-light gallery-item">
	<a href="http://winkis.in/" target={"_blank"}>
	<img src={img11} alt="Placeholder Image" className="img-fluid" />
     
      <div className="mask flex-center">
        <p className="white-text">Winkis</p>
      </div>
	  </a>
    </div>




    <div className="view overlay hm-black-light gallery-item">
	<a href="http://sewingthreads.in/" target={"_blank"}>
    <img src={img04} />
      <div className="mask flex-center">
        <p className="white-text">Sewing Threads</p>
      </div>
	  </a>
    </div>
	

	<div className="view overlay hm-black-light gallery-item">
	<a href="https://gr8labs.com/" target={"_blank"}>
    <img src={img14} />
      <div className="mask flex-center">
        <p className="white-text">Gr8labs</p>
      </div>
	  </a>
    </div>

	
	<div className="view overlay hm-black-light gallery-item">
	<a href="http://indiancoalcompany.com/" target={"_blank"}>
    <img src={img15} />
      <div className="mask flex-center">
        <p className="white-text">Indian coal company</p>
      </div>
	  </a>
    </div>

	
	<div className="view overlay hm-black-light gallery-item">
	<a href="https://arabicalounge.com.au/" target={"_blank"}>
    <img src={img16} />
      <div className="mask flex-center">
        <p className="white-text">Arabica Lounge</p>
      </div>
	  </a>
    </div>

	<div className="view overlay hm-black-light gallery-item">
	<a href="http://kaveishbioenergy.in/" target={"_blank"}>
    <img src={img17} />
      <div className="mask flex-center">
        <p className="white-text">Kaveish Bioenergy</p>
      </div>
	  </a>
    </div>

	<div className="view overlay hm-black-light gallery-item">
	<a href="https://greenoxgases.com/" target={"_blank"}>
    <img src={img18} />
      <div className="mask flex-center">
        <p className="white-text">Greenox Gases</p>
      </div>
	  </a>
    </div>
	<div className="view overlay hm-black-light gallery-item">
	<a href="http://awsomebrews.com/" target={"_blank"}>
    <img src={img19} />
      <div className="mask flex-center">
        <p className="white-text">Awsome Brews</p>
      </div>
	  </a>
    </div>



	</div>



  </div>
</div>
		
<a href="https://wa.me/+919159565854?text=Hello BPL!" className="whatsapp-button" target="_blank">
    
    <img src={require('../images/WhatsApp.svg').default} alt='whatsapp' />
    </a>

        </div>

    );
}
export default Portfolio;