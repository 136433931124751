import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
function Contact()
{
    useEffect(() => {
        document.title = 'Contact - Blackpearllogics';
      }, []);

    return(
<div>
<div className="container-fuild abt-sec1">
            <div className="container">
               <div className="row">
                <div className="col-sm-12">

               
                <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/"><img src={require('../images/icons/home.png')} /> Home</Link></li>
    <li class="breadcrumb-item active" aria-current="page">Contact us</li>
  </ol>
</nav>
</div>
               </div>
                
            </div>

        </div>
        <div className="container-fuild con-sec1">
            <div className="row">

                <div className="col-lg-4 col-xs-12 section__contact">
                    
                   <div className="row Office">
                   
                    <h5 className="text-end">Office address  <img src={require('../images/office.svg').default} alt='mySvgImage' /></h5>
                    <p className="text-end">50-3, Anna Nagar
1<sup>st</sup> Floor,<br/>
Arun Hospital Upstairs,<br/>
Dharapuram - 638656<br/>
INDIA</p>
<p className="text-end">
<span className="line"></span></p>
<h5 className="text-end">Phone number  <img src={require('../images/phone.svg').default} alt='mySvgImage' /></h5>
<p className="text-end"><a href="tel:+919159565854">91-91595 65854</a></p>
<p className="text-end">
<span className="line"></span></p>
<h5 className="text-end">Email address <img src={require('../images/email.svg').default} alt='mySvgImage' /></h5>
<p className="text-end"><a href="mailto:info@blackpearllogics.com">info@blackpearllogics.com</a></p>
<p className="text-end">
<span className="line"></span></p>
</div>
                   
                  

                </div>
                <div className="col-lg-8 col-xs-12 Contact home-sec-2" >
                <div className="row mb-4">
                <div className="col-lg-11 col-xs-12">
                    <div className="col-sm-12 heading">
                        <h2>
                        Contact Us
                        </h2>
                        <p>
                        We would be pleased to hear from you. We Pride our self on quick replies for any of your queries. Please Feel free to contact us for all your web development queries. We look forward to serve you with full commitment.
                        </p>
                       

                    </div>

                    <form method="post" action="sendmail.asp">
                    <div className="row input-container">
			<div className="col-xs-12">
				<div className="styled-input wide">
					<input type="text" name="name" required />
					<label>Name</label> 
				</div>
			</div>
			<div className="col-md-6 col-sm-12">
				<div className="styled-input">
					<input type="email" name="email" required />
					<label>Email</label> 
				</div>
			</div>
			<div className="col-md-6 col-sm-12">
				<div className="styled-input">
					<input type="text" name="Phone" required />
					<label>Phone Number</label> 
				</div>
			</div>
			<div className="col-xs-12">
				<div className="styled-input wide">
					<textarea required name="Message"></textarea>
					<label>Message</label>
				</div>
			</div>
			<div className="col-lg-4 col-xs-12">
				
                <input type="submit" className="btn-lrg submit-btn" value="Send Message" />
			</div>
	</div>


                    </form>
              
              </div>
                </div>
                    
                </div>
                
            </div>

        </div>
<div className="responsive-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3920.005609992945!2d77.51809607408693!3d10.73405025997586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba9bf83eb908ddf%3A0xf335c8567de946d3!2sBlack%20Pearl%20Logics!5e0!3m2!1sen!2sin!4v1703741166210!5m2!1sen!2sin" width="600" height="100" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <a href="https://wa.me/+919159565854?text=Hello BPL!" className="whatsapp-button" target="_blank">
    
    <img src={require('../images/WhatsApp.svg').default} alt='whatsapp' />
    </a>

</div>

    );
}
export default Contact;
