import React from 'react'; 
import {Link} from 'react-router-dom';


function Home()
{
    return(
        <div>
        <div className="container-fuild home-sec-1">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 banner-img">
                     
                        <span>Unleashing Creative Excellence in</span><h1>Website Designing, Web Applications, Mobile Apps and Graphical Designing</h1><span>Transforming Ideas into Stunning Online Realities.</span>
                        <div className="row mt-4">
                            <div className="col-sm-12 col-12">
                                <Link to="/Services" class="btn btn-red banner-btn">Our Services</Link>
                                <Link to="/portfolio" class="btn btn-them banner-btn">Portfolio</Link>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-sm-6 banner-left">
                        <div className="flyIn">
                            <img src={require('../images/banner_icon.png')} />
                        </div>
                        <img src={require('../images/banner-men.png')} />
                    </div>
                </div>
            </div>
        </div>
    
        <div className="container-fuild home-sec-2">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-12 heading text-center">
                        <h2>
                        Elevate Your Online Presence<br />
                        with Expert Web Solutions!
                        </h2>
                        <p>
                        Our expert team combines cutting-edge technology with artistic flair to create stunning,<br/>user-friendly websites that captivate audiences and convert visitors into loyal customers.
                        </p>
                    </div>
                </div>
                <div className="row home-sec-2-row">
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-1"></div>
                            <div className="col-9">
                                <h3>All in one package</h3>
                               <p>Blackpearllogics offers "all-in-one" package for a website typically includes a comprehensive range of services to cover all aspects of creating and launching a website.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-2"></div>
                            <div className="col-9">
                                <h3>Consultation and Planning</h3>
                                <p> Initial consultation to understand your goals, target audience, and preferences.
        Development of a project plan and timeline.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-3"></div>
                            <div className="col-9">
                                <h3>Domain Name and Hosting</h3>
                                <p> Assistance with selecting and registering a domain name (your website's address).
        Set up and configuration of web hosting services.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-4"></div>
                            <div className="col-9">
                                <h3>Design and Development</h3>
                                <p>Customized website design based on your brand and preferences.
        Creation of a responsive layout (compatible with all devices).
        Development of essential pages (home, about, services, contact, etc.).
        Integration of interactive elements (forms, sliders, galleries, etc.).</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-5"></div>
                            <div className="col-9">
                                <h3>Content Creation and Integration</h3>
                               <p> Assistance with content creation or migration (text, images, videos, etc.).
        Integration of content into the website in a visually appealing manner.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-6"></div>
                            <div className="col-9">
                                <h3>SEO and Optimization</h3>
                                <p>On-page SEO optimization to improve search engine rankings.
        Optimization for speed and performance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-7"></div>
                            <div className="col-9">
                                <h3>Security and Maintenance</h3>
                                <p>Implementation of security measures to protect against threats and vulnerabilities.
        Regular updates, backups, and maintenance to keep the site running smoothly.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-8"></div>
                            <div className="col-9">
                                <h3>Testing and Quality Assurance</h3>
                                <p>Thorough testing to ensure the website functions properly across different browsers and devices.</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-9"></div>
                            <div className="col-9">
                                <h3>Training and Support</h3>
                                <ul>
                                    <li>Training on how to update and manage the website (if needed).</li>
                                    <li>Ongoing support for any technical issues or updates.</li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-10"></div>
                            <div className="col-9">
                                <h3>Analytics and Reporting</h3>
                                <ul>
                                    <li>Integration of analytics tools to track website traffic and user behavior.</li>
                                    <li>Regular reporting on website performance.</li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>  <div className="col-sm-4">
                        <div className="row mb-3">
                            <div className="col-3 bg-icon-11"></div>
                            <div className="col-9">
                                <h3>  Additional Features (Optional):</h3>
                                <ul>
                                    <li>E-commerce functionality for online stores.</li>
                                    <li>Blog integration for content marketing.</li>
                                    <li>Social media integration.</li>
                                    <li>Newsletter sign-up forms.</li>
                                </ul>
                               
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
  <div className="container-fuild section-divider-bg-right">
            <div className="container">
                <div className="row ">
                    
                    </div>
                    </div>
                    </div>
                
        <div className="container-fuild home-sec-3">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-sm-8">
                   
                    <img src={require('../images/using_software.png')} />
                    </div>
                    <div className="col-sm-4 heading-left ">
                        <h2>About Us</h2>
                        <p>Blackpearllogics is  where innovation meets expertise in the dynamic world of web development. We are a team of passionate professionals dedicated to crafting exceptional online experiences that drive results.</p>
                        <Link to="/Contact" class="btn btn-them ">Contact</Link>

                    </div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="container-fuild section-divider-bg-left">
            <div className="container">
                <div className="row ">
                    
                    </div>
                    </div>
                    </div>




                    <div className="container-fuild home-sec-4">
            <div className="container">
                <div className="row align-items-center">

                <div className="col-sm-4 heading-left ">
                        <h2>Our Mission</h2>
                        <p>At Blackpearllogics, our mission is to empower businesses and individuals with cutting-edge web solutions that not only look stunning but also perform seamlessly. We believe that a website is more than just a digital presence – it's a powerful tool to connect, engage, and convert.</p>
                        <Link to="/Contact" class="btn btn-them ">Contact</Link>

                    </div>

                    <div className="col-sm-8">
                   
                    <img src={require('../images/businessman.png')} />
                    </div>
                 
                    
                    </div>
                    </div>
                    </div>

                    <div className="container-fuild section-divider-bg-right">
            <div className="container">
                <div className="row ">
                    
                    </div>
                    </div>
                    </div>

                    <div className="container-fuild home-sec-5">
            <div className="container">
                <div className="row align-items-center">

                <div className="col-sm-8">
                   
                   <img src={require('../images/lady_with_handphone.png')} />
                   </div>
                

                <div className="col-sm-4 heading-left ">
                        <h2>Our Services</h2>
                        <ul>
                        <li>Logo Designing
</li><li>Web Designing
</li><li>Domain and Hosting Solutions
</li><li>SMS,E-Mail & Whats App Solutions
</li><li> SEO
</li><li> E-Commerce
</li><li> Payment Gateway Solutions
</li><li>Mobile Applications
</li><li> Customized Web Applications</li>
                        </ul>
                        <Link to="/Contact" class="btn btn-them ">Contact</Link>

                    </div>

                
                    
                    </div>
                    </div>
                    </div>
                
                    

                    <a href="https://wa.me/+919159565854?text=Hello BPL!" className="whatsapp-button" target="_blank">
    
    <img src={require('../images/WhatsApp.svg').default} alt='whatsapp' />
    </a>

    </div>
    
    );
}
export default Home;