import React from 'react'; 

import {Link} from 'react-router-dom';

function Footer()
{
    console.log(new Date().getFullYear());
    return(
        <div>
        <div className="container-fuild home-sec-6">
        <div className="container">
                <div className="row align-items-center">

                    <div className='col-sm-12'>
                        <h3>Need help's... Feel fee to contact us</h3>
                        <Link to="/contact">Get in touch <img src={require('../images/icons/arrow.png')}  /></Link>
                    </div>

                    </div></div>

            </div>
        <footer className='container-fuild footer'>
                    <div className='container'>
                        <div className='row mb-3'>
                        <div className="col-sm-4">
                        <img src={require('../images/black-pearl-logics.png')}  />
                        <p className='text-justify'>At Black Pearl Logics we aim to provide exactly that a complete range of professional quality web design, web development and internet marketing solutions, Each one in our team is tailored to meet your business needs.</p>
                            </div>
                            <div className="col-sm-4">
                            <h3 className='footer-heading'>Services</h3>
                                <div className='row'>
                                    <div className='col-6'>
                                    <ul className='Services-footer'>
                                    <li>Web Designing</li>
                                    <li>Web Development</li>
                                    <li>Graphical Designing</li>
                                    <li>Domain Registration</li>
                                   
                                </ul>


                                    </div>
                                    <div className='col-6'>
                                    <ul className='Services-footer'>
                                 <li>E-Mail Alerts</li>
                                    <li>E-Commerce</li>
                                    <li>MLM Websites</li>
                                
                                    <li>Web Hosting</li></ul>
                                        </div>
                                </div>
                                

                               
                               

                            </div>
                            <div className="col-sm-4">
                            <h3 className='footer-heading'>Contact</h3>

                            <ul className='footer-map'>
                                <li><Link to="https://maps.app.goo.gl/PFoqcpmDrJpL2fzT9" target={'_blank'}><img src={require('../images/icons/location.png')}  /> Black Pearl Logics, 50-3, Anna Nagar, 1st Floor, Arun Hospital Upstairs, Dharapuram - 638656 INDIA</Link></li>
                                <li><Link to="tel:+919159565854"> <img src={require('../images/icons/call.png')}  /> 91-91595 65854</Link></li>
                                <li><Link to="mailto:info@blackpearllogics.com"><img src={require('../images/icons/mail.png')}  /> info@blackpearllogics.com</Link></li>
                                
                            </ul>
                            </div>
                           

                        </div>
                        
                        <div className='row'>
                        <div className="col-sm-12">
                            <p className='copy-write'>© {new Date().getFullYear()}. All Rights Reserved.</p>
                            </div>
                            </div>

                    </div>

                </footer>
                </div>
                
    );
}

export default Footer;