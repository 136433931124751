import React, { useEffect, useState } from 'react'; 

import { useRef } from "react";

import {Link, useLocation } from 'react-router-dom';
import Headroom from 'react-headroom';

function Navbar()
{
    const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

    const isActive = {
        fontWeight: "bold",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      };

    const collapseRef = useRef(null);
  
    const hideBars = () => {
      collapseRef.current.setAttribute("class", "navbar-collapse collapse");
    };


    return(
        <Headroom>
        <nav class="navbar navbar-expand-md fixed-top menu">
        <div class="container"> 
    
        
              

            <Link to="/" class="navbar-brand"><img src={require('../images/black-pearl-logics.png')}  /></Link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse" ref={collapseRef}>
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                  
                </ul>
                <div class="d-flex" role="search">
                    
                    <ul class="navbar-nav me-auto mb-2 mb-md-0">
                        <li class="nav-item">
                           
                            <Link to="/"  className={"nav-link" + (url === "/" ?" active" : "")} onClick={hideBars}>Home</Link>
                        </li>
                        <li class="nav-item">
                            
                            
                            <Link to="/About" className={"nav-link" + (url === "/About" ?" active" : "")} onClick={hideBars}>About</Link>
                        </li>

                                         <li class="nav-item">
                            
                            
                            <Link to="/portfolio" className={"nav-link" + (url === "/portfolio" ?" active" : "")} onClick={hideBars}>Portfolio</Link>
                        </li>
                       

 
                        <li class="nav-item">
                          <Link to="/Services" className={"nav-link" + (url === "/Services" ?" active" : "")} onClick={hideBars}>Our Services</Link>
                        </li>


                        <li class="nav-item">
                          <Link to="/Contact" className={"nav-link" + (url === "/Contact" ?" active" : "")} onClick={hideBars}>Contact</Link>
                        </li>












                    </ul>
                </div>
            </div>
        </div>
    </nav>
    </Headroom>

    );
}
export default Navbar;