import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
function About()
{
    useEffect(() => {
        document.title = 'About Us-  Blackpearllogics';
      }, []);

    return(
        <div>

       
        <div className="container-fuild abt-sec1">
            <div className="container">
               <div className="row">
                <div className="col-sm-12">

               
                <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/"><img src={require('../images/icons/home.png')} /> Home</Link></li>
    <li class="breadcrumb-item active" aria-current="page">About us</li>
  </ol>
</nav>
</div>
               </div>
                
            </div>

        </div>

        <div className="container-fuild abt-sec2">
            <div className="container">
               <div className="row">
                <div className="col-sm-12">
                        <img src={require('../images/websitedesign.jpg')} className='about' /> 
                        <div className="row">
                            <div className="col-sm-4 about-sec-2">
                                <div className="card">
                                <h2 className="abou-heading">Hello Buddy..!<br/>Welcome to BPL</h2>
                                <p><img src={require('../images/icons/quote.png')} className='quote' /> We are build awesome marketing template</p>
                                </div>
                                
                                
                            </div>
                            <div className="col-sm-1">

</div>
                            <div className="col-sm-7">
                                <p className="mt-4 text-justify">

                                At Black Pearl Logics we aim to provide exactly that a complete range of professional quality web design, web development and internet marketing solutions, Each one in our team is tailored to meet your business needs.Having a contemporary, relevant and properly marketed website has become an expected standard in online business. Not only will it work to increase your business potential, it also helps to provide a sense of security for new and existing customers looking to use your company.
                                </p>
                            </div>
                           
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-justify">We offer a comprehensive range of software and IT solutions, including packaged applications for the Banking, Financial Services , Insurance (BFSI), Manufacturing, Contracting, and Retail , Distribution industries. In addition, it offers a broad range of software services such as Custom Software Development, IT Consulting, and IT Security Consulting, Enterprise Application Integration (EAI), Managed IT Services.
</p>
<p className="text-justify">Black Pearl Logics is a customized software development company which provides technical solutions to over a number of customers, spanning a range of verticals Banking, Insurance, Manufacturing, Textile Industry, Retail & Distribution, Net work marketing (MLM), Web site's monitoring etc.,
</p>
<p className="text-justify">We offers web designing services at most competitive prices. As a best web designing company Black Pearl Logics are offering numerous web services including web design, custom web application development, web page design, web development and advanced web programming. Emerging technology has opened up whole new ways to communicate and revealed almost endless business opportunities.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>


                    <div className="container-fuild abt-sec3">
	<div className="container">
		<div className="row">
			<div className="col-lg-6 col-xs-12">
                <h4>Vision</h4>
                <p>To be a leading force in shaping the digital landscape by delivering 
innovative, user-centric, and scalable web solutions that empower 
businesses to thrive in the ever-evolving online world.</p>
<Link to="/Contact" className="btn btn-danger">Contact us</Link>

            </div>
            <div className="col-lg-6 col-xs-12">
                <h4>Mission </h4>
                <p>Striving for perfection in every line of code, pixel of design, and functionality of our web solutions.</p>
                <Link to="/Contact" className="btn btn-danger">Contact us</Link>
            </div>
		</div>
	</div>
</div>
<a href="https://wa.me/+919159565854?text=Hello BPL!" className="whatsapp-button" target="_blank">
    
    <img src={require('../images/WhatsApp.svg').default} alt='whatsapp' />
    </a>

        </div>

    );
}
export default About;