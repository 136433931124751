
import Navbar from './components/inc/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Portfolio from './components/pages/Portfolio';
import Services from './components/pages/Services';
import Footer from './components/inc/Footer';


import { Routes, Route, HashRouter  } from "react-router-dom";


import React from 'react';
function App() {
  return (
   
      
    
  <div>
  

  <HashRouter>
  <Navbar /> 
    <Routes>
      <Route exact path="/" element={<Home />} />
      
      <Route exact path="/About" element={<About />} />
      <Route exact path="/Portfolio" element={<Portfolio />} />
      <Route exact path="/Services" element={<Services />} />
      <Route exact path="/Contact" element={<Contact />} />
    </Routes>

   

    <Footer /> 
  </HashRouter>

    
   
   
  </div>
 
  );
}

export default App;
